import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isL1Followed, printHTML } from '@utility/Api';
import ButtonV2 from '@components/UI/ButtonV2';
import { isBrandPage, isBrandTPSubcataloguePage } from '@components/link-utils';
import { getBrandPageLogo , getLevelLabel } from '@utility/levelUtility';
import PageTopTitle from '@components/UI/PageTopTitle';
import { BRANDS_RESIZE_LIST } from '@utility/const';
import LevelBreadcrumb from '@components/UI/LevelBreadcrumb';
import LazySVG from '@components/LazySvg';
import { useSelector } from '@hooks/redux';
import { followL1, setCatalogueDocTitle } from '@redux-actions/';
import HelmetMetaTags from '@components/HelmetMetaTags';
import { cmMetaData } from '@utility/previewUtils';
import { buildClickDataTrackingObject } from '@model/TrackingClass';
import clsx from '@utility/clsx';

type Props = {
  location: any;
  category: 'brand' | 'channel';
  brand: string;
  setSubCategoryTitle?: (subCategoryTitle: string) => void;
  isNotBrandPage?: boolean;
  showTitle?: boolean;
  showLevelBreadcrumb?: boolean;
};

const BrandPageTop = ({
  location,
  category,
  brand,
  setSubCategoryTitle,
  isNotBrandPage,
  showTitle,
  showLevelBreadcrumb = false,
}: Props) => {
  const dispatch = useDispatch();

  const lang = useSelector(state => state.utils.lang);
  const brandPageInfo = useSelector(state => state.cms.brandPageInfo?.[brand]);
  const userProfile = useSelector(state => state.user.userProfile);
  const isUserUpdateLoading = useSelector(state => state.user.isUserUpdateLoading);
  const catalogueTitle = useSelector(state => state.course.catalogueTitle);
  const labelL0Map = useSelector(state => state.utils.labelL0Map);
  const labelL1Map = useSelector(state => state.utils.labelL1Map);
  const l1Map = useSelector(state => state.utils.l1Map);

  const [isAtolL0, setIsAtolL0] = useState(false);

  const [hideImage, setHideImage] = useState({ value: false, set: false });
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (isNotBrandPage && !showTitle) {
      return;
    }
    let newTitle = '';
    if (
      isBrandPage(location.pathname) ||
      showTitle ||
      isBrandTPSubcataloguePage(location.pathname)
    ) {
      newTitle = getLevelLabel(brand, labelL1Map);
    } else {
      if (catalogueTitle && !showTitle) {
        newTitle = catalogueTitle ? catalogueTitle : brand;
      } else {
        newTitle = getLevelLabel(brand, labelL1Map);
      }
    }
    setTitle(newTitle);
    setSubCategoryTitle?.(newTitle);
    dispatch(setCatalogueDocTitle(newTitle));

    let newDescription = labelL1Map?.[brand]?.description;
    if (!newDescription) {
      newDescription = '';
    }
    setDescription(newDescription);
    // console.log("BRAND", brand)
  }, [location.pathname, catalogueTitle, lang, labelL1Map]);

  useEffect(() => {
    if (brand) {
      //derive L0 from L1
      const derivedL0Id = l1Map[brand]?.l0?.key;
      const l0Obj = labelL0Map[derivedL0Id];

      if(l0Obj && l0Obj?.label?.toLowerCase() === 'atol') setIsAtolL0(true);
    }
  }, [brand, l1Map]);

  const handleFollow = () => {
    if (brand && userProfile) {
      dispatch(followL1(brand, !isL1Followed(brand, userProfile)));
    }
  };

  const isLogoPresent = () => {
    return (
      brandPageInfo?.extendedFields?.Banner?.length > 0 ||
      (!hideImage.value && category === 'brand')
    );
  };

  return (
    <section className="brandpage__banner-wrapper" aria-label={lang?.[title] || title} {...cmMetaData(brandPageInfo?.contentId)}>
      {!isNotBrandPage && <HelmetMetaTags title={title} useTitleFormatLeonardo={true} />}

      {showLevelBreadcrumb && <LevelBreadcrumb variant="large" l1Id={brand} showL1={false} isAtolL0={isAtolL0}/>}

      {(brandPageInfo || isNotBrandPage) && (  
        <>
          {brandPageInfo?.extendedFields?.Banner?.length > 0 ? (
            <div className="brandpage__banner">
              <img src={brandPageInfo.extendedFields.Banner[0].data} />
            </div>
          ) : (
              <div className={clsx("brandpage__logo", {
                "atol": isAtolL0
              })}>
              {!hideImage.value && category === 'brand' && !isAtolL0 && (
                <LazySVG
                  className={BRANDS_RESIZE_LIST.includes(brand) ? 'brandResize' : ''}
                  src={getBrandPageLogo (brand)}
                  alt={brand}
                  onError={() => {
                    setHideImage({ value: true, set: true });
                  }}
                />
              )}

              {isAtolL0 && <LazySVG className="atol-logo" src="/images/logo-app/ATOL_Sup.svg" alt="atol logo" />}
            </div>
          )}
          {title && !isLogoPresent() && (
            <PageTopTitle className="brandpage__title" title={lang[title] || title} />
          )}
          {!isNotBrandPage && (
            <>
              {brandPageInfo.htmlDescription && (   
                <p
                  className="page-top-title__description"
                  dangerouslySetInnerHTML={printHTML(brandPageInfo?.htmlDescription)}
                  {...cmMetaData(brandPageInfo?.contentId)}></p>
              )}
              {description && <p className="page-top-title__description">{description}</p>}
              <ButtonV2
                variant="secondary"
                small
                className="course-catalogue__follow"
                handleClick={()=>{isL1Followed(brand, userProfile) ? buildClickDataTrackingObject("unfollow") : buildClickDataTrackingObject("follow");handleFollow();}}
                disabled={isUserUpdateLoading}
              >
                {isL1Followed(brand, userProfile) ? lang?.UNFOLLOW : lang?.FOLLOW}
              </ButtonV2>
              {catalogueTitle && isLogoPresent() && (
                <h2 className="brandpage__title">{lang[catalogueTitle] || catalogueTitle}</h2>
              )}
            </>
          )}
        </>
      )}
    </section>
  );
};

export default BrandPageTop;
