import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';

import { Course, CourseL1, CourseLanguage, CoursesMap, LangMap } from '@model/CoursesClass';
import * as actions from '../../store/actions';

import CardSmall from './CardSmall';
import {
  isMultiActivity,
  isMandatory,
  printDurationHourFormat,
  isCompletedCourse,
  isStartedCourse,
  getCourseCompatibility,
  getVideoPreviewUrl,
  isL1Course,
  printHTML,
  isLearningPath,
  isLivestreamCourse,
  formatCurrency,
  isVirtualClassroom,
  getCoursesWithinLP,
  printCtype,
  getVTTProgramUrl,
  isCourseCertificateCtype,
} from '@utility/Api';

import { ctype } from '@utility/const';
import { CardOverview } from '.';
import { useTranslation } from 'react-i18next';
import { GradientStyle } from '@model/GradientClass';
import { TealiumEvents } from '@model/TrackingClass';
import { UserProfile } from '@model/User';
import { isBycEnabled, isUserEl360 } from '@utility/userUtility';
import { isLearningPathType } from '@utility/LPUtility';
import CardLessonInLP from './CardLessonInLP';
import { getLivestreamUrls, isLivestreamLiveNow } from '@utility/LivestreamUtility';
import CardControllerProduct from './CardControllerProduct';
import { TrainingPill } from '@model/ProductClass';
import { RootState } from 'src/store/store';
import { canCourseBeAccessed, isPriceable, isPurchased } from '@utility/ecommerceUtility';
import CardSearch from './CardSearch';
import CardL1Search from './CardL1Search';
import { getLevelLabel } from '@utility/levelUtility';
import { useSelector } from '@hooks/redux';
import { checkUpcomingOrPastEvent } from '@hooks/useUpcomingEvents';
import {
  BadgeCourseClass,
  getBadgeCourse,
  getBadgeProgram,
  getCourseFullName,
  isCourseEl360,
  isCourseAssessment,
  isCourseComingSoon,
} from '@utility/CourseUtility';
import CardActivity from '@components/CardActivity';
import CardUpcomingEvents from './CardUpcomingEvents';
import CardBigEvolution from './CardBigEvolution';
import CardLargeEvolution from './CardLargeEvolution';
import CardL1Big from './CardL1Big';
import CardEducationalPathsProgram from './CardEducationalPathsProgram';
import CardEducationalPathsSection from './CardEducationalPathsSection';
import CardHighlightsMenu from '@components/UI/CardHightlightsMenu';
import CardAreaOfExpertise from '@components/CardV3/CardAreaOfExpertise';
import {
  isAssignmentLesson,
  isSupportLessonInOnboarding,
  isOneToOneLesson,
  isSupportMaterialLesson,
  isCourseOnBoarding,
  isCourseMaster,
} from '@utility/onBoardingCourseUtility';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import { L1Element } from '@model/L1Element';
import { USER_URLS } from '@components/link-utils';
import { useHistory } from 'react-router-dom';
import CardRoleSpecificProgramEducationalPath from './CardRoleSpecificProgramEducationalPath';
import { isCourseCareerMaster } from '@utility/buildYourCareerUtility';
import { useInView } from 'react-intersection-observer';
import { TCardList } from '@components/CMSNewPages/components/CardList';

type Props = PropsFromRedux & {
  course: Course | CourseL1;
  product?: TrainingPill;
  lang: LangMap;
  isLoadingWishlist: boolean;
  toggleWish: (
    courseId: string,
    wishlistValue: number,
    isInPopup?: boolean,
    currentCourseModal?: Course
  ) => void;
  toggleModal: (show: boolean, course: Course) => void;
  variant:
  | 'small'
  | 'overview'
  | 'lesson-in-lp'
  | 'training-pill'
  | 'upcoming-events'
  | 'search'
  | 'lesson-in-onboarding'
  | 'card-evolution'
  | 'card-large-evolution'
  | 'card-educational-paths-program'
  | 'card-role-specific-program-educational-path'
  | 'card-educational-paths-section'
  | 'card-educational-paths-section-master'
  | 'card-educational-paths-section-master-career'
  | 'card-highlights-menu'
  | 'card-areaofexpertise';
  currentCourse: Course;
  showLaunchButton?: boolean;
  userProfile: UserProfile;
  gradientStyle: GradientStyle;
  subtitle?: CourseLanguage;
  tabIndex?: number;
  toggleIframePopup: (
    value: boolean,
    course: Course,
    url: string,
    urlToCheck: string,
    isVideo: boolean,
    urlsLivestream?: any
  ) => void;
  isParentLpBlocked?: boolean;
  blockingLps?: Course[];
  delayGetCourseDetails?: boolean;
  setShowModalCart: (show) => void;
  ecommerceEnable: boolean;
  noHoverEffect?: boolean;
  delayImageLoading?: boolean;
  id?: string;
  orderNum?: number;
  'data-element-id'?: string;
  onHover?: () => void;
  onClick?: () => void;
  className?: string;
  overwriteOnClick?: (course: Course) => void;
  beforeOnClickCard?: (course: Course | L1Element,elementPosition:number) => void;
  postAction?: () => void;
  hidePricelabel?: boolean;
  breakpoint?: ReturnType<typeof useBreakpointBoolean>;
  setShowShareModal?:( showShareModal: boolean ) => void;
  typeHarvard?: string;
  elementPosition?: number;
  courseEl360?: string;
  isCourseComingSoon?: boolean;
  isCertificate?: boolean;
  isAssessment?: boolean;
  careerProgramType?: string;
  isAdvancedCompletedCourse?: boolean;
  isUpskillProgram?: boolean;
  // Show tick if all LP's courses are completed
  showTickOnCompleteLP?: boolean;
  coursesContentId?: number,
  isProductCarousel?: boolean
  isProgramLocked?: boolean;
  fatherCourseId?: Course["courseIdMaster"]
  // for cms new pages
  isInCmsNewPage?: boolean;
  clickObjectTracking?:string;
  clickObjectTrackingDescription?: string;
  learningPath?:Course;
  noLazyLoadImage?: boolean;
};

const CardController = (props: Props): ReactElement => {
  const {
    course,
    lang,
    isLoadingWishlist,
    toggleWish,
    toggleModal,
    variant = 'card-evolution',
    userProfile,
    tabIndex = 0,
    setShowShareModal,
    elementPosition ,
    careerProgramType,
    isAdvancedCompletedCourse,
    coursesContentId,
    fatherCourseId,
    isInCmsNewPage = false,
    clickObjectTracking,
    clickObjectTrackingDescription,
    learningPath
  } = props;

  if (!props.course) {
    return null;
  }

  const history = useHistory();
  const dispatch = useDispatch();
  const coursesMap: CoursesMap = useSelector(state => state.course.coursesMap);
  const labelComplexityMap = useSelector(state => state.utils.labelComplexityMap);
  const isLoadingCourseSummary = useSelector(state => state.course.isLoadingCourseSummary);

  const [ctypeName, setCtypeName] = useState('');
  const [duration, setDuration] = useState('');
  const [iconName, setIconName] = useState('');
  const [badge, setBadge] = useState<BadgeCourseClass>(new BadgeCourseClass());

  // const [courseStatus, setCourseStatus] = useState('');
  const [showBar, setShowBar] = useState(false);
  const [valueBar, setValueBar] = useState(0);

  const [isNotYetAvailable, setIsNotYetAvailable] = useState(false);

  const [teacherName, setTeacherName] = useState('');

  const [compatibility, setCompatibility] = useState(0);

  const [isLoadingWishlistLocal, setIsLoadingWishlistLocal] = useState(false);
  
  //LEON-7000
  const [wishlistRecommendedSection, setWishlistRecommendedSection] = useState<number>(0);

  const isBycUser = isBycEnabled(userProfile)

  const [cardRef, inView] = useInView({threshold: 0})

  const { t } = useTranslation();
  useEffect(() => {
    if (!course) {
      return;
    }
    const translate = !isLearningPathType(course) && course.isStatic ? t : null;
    //get course duration
    const totalLearningPathDuration = course.totalLearningPathDuration? (course.totalLearningPathDuration !==0 ? course.totalLearningPathDuration : course.duration) : course.duration;
    const durationTemp = printDurationHourFormat(totalLearningPathDuration, lang, translate, false);
    setDuration(durationTemp);
    //get course icon
    if (course.ctype && course.ctype.length > 0) {
      let iconNameTemp = null;
      if (isMultiActivity(course)) {
        iconNameTemp = ctype.MULTIACTIVITY;
      } else if (isLearningPath(course)) {
        iconNameTemp = ctype.LEARNING_PATH;
      } else {
        iconNameTemp = course.ctype[0];
      }
      if (iconNameTemp) {
        setIconName(iconNameTemp);
      }
    }

    //get course status
    // setCourseStatus(getCourseStatus());

    if (variant === 'overview') {
      if (isMultiActivity(course) && course.numActivities) {
        setShowBar(true);
        setValueBar(
          course.numActivities.totalActivities > 0
            ? (course.numActivities.completedActivities / course.numActivities.totalActivities) *
            100
            : 0
        );
      }

      if (isLearningPath(course) && isStartedCourse(course)) {
        const lpCourses = getCoursesWithinLP(props.course, coursesMap);
        let number = 0;
        lpCourses.forEach(course => {
          if (isCompletedCourse(course)) {
            number = number + 1;
          }
        });
        setShowBar(true);
        setValueBar(lpCourses?.length > 0 ? (number / lpCourses?.length) * 100 : 0);
      }
    } else {
      if (isStartedCourse(course)) {
        setShowBar(true);
        setValueBar(50);
      } else if (isCompletedCourse(course)) {
        setShowBar(true);
        setValueBar(100);
      } else {
        setShowBar(false);
        setValueBar(0);
      }
    }

    //set if course is not available
    setIsNotYetAvailable(
      variant === 'lesson-in-lp' || variant === 'lesson-in-onboarding'
        ? course.isNotYetAvailable
        : false
    ); //!isCourseVisible(course)

    if (course.teacherName) {
      setTeacherName(course.teacherName);
    }

    setCompatibility(getCourseCompatibility(course));
  }, [course, lang]);

  useEffect(() => {
    //get badge label
    let badge = getBadgeCourse(course, variant, lang);
    const showBadgeCardEdPath = !badge.priority && !isPurchased(course) 
      && variant === 'card-educational-paths-program' 
      // || variant === 'card-role-specific-program-educational-path';
    if (variant === 'card-educational-paths-section-master' 
      || variant === 'card-educational-paths-section-master-career' 
      // || variant === 'card-role-specific-program-educational-path' 
      || showBadgeCardEdPath
    ) {
      badge = getBadgeProgram(course, lang, userProfile);
    }

    setBadge(badge);
  }, [course, lang, userProfile]);

  useEffect(() => {
    if (variant !== 'training-pill') {
      if (!isLoadingWishlist && isLoadingWishlistLocal) {
        setIsLoadingWishlistLocal(isLoadingWishlist);
      }
    }
  }, [isLoadingWishlist]);

  useEffect(() => {
    setCtypeName(
      course?.isStatic
        ? //if static --> use just labels
        printCtype(course.ctype, null, t)
        : //if not static --> ctype name no more related to ctype but to complexity
        getLevelLabel(course?.complexityId, labelComplexityMap)
    );
  }, [course?.complexityId, labelComplexityMap]);

  const handleToggleWish = (e?) => {
    if (e) {
      e.stopPropagation();
    }

    toggleWishlist(e);
  };

  const openCourseModal = () => {
    if (props.beforeOnClickCard) {
      props.beforeOnClickCard(course,elementPosition);
    }

    if (props.overwriteOnClick) {
      props.overwriteOnClick(course);
      return;
    }
    const element = document.querySelector('.modal.modal__course-overview');
    if (element) {
      element.scrollBy({ top: 0, left: 0, behavior: 'auto' });
    }

    if (props.onClick) {
      props.onClick();
    }

    if (isCourseOnBoarding(course)) {
      window.sessionStorage.removeItem("Products_ContextId_Array");
      // HANDLE MASTER FOR BYC
      if(isCourseCareerMaster(course) && isBycUser && location.pathname.includes(USER_URLS.BUILD_YOUR_CAREER.URL)) {
        history.push(USER_URLS.MANAGEMENT_DEVELOPMENT_PROGRAM_PROGRAM.URL.replace(":id", course.courseIdMaster));
      } else { //OTHERWISE HANDLE IT HAS ALWAYS
        history.push(USER_URLS.ONBOARDING_COURSE_ID.URL.replace(":id", course.courseIdMaster));
      }
    } else {
      window?.sessionStorage?.setItem("Products_ContextId_Array", props.fatherCourseId)
      toggleModal(true, course);
    }
  };

  const toggleWishlist = (e) => {
    // @todo add to wishlist lp
    const recommendedSectionExists = e?.currentTarget?.closest(".recommended-section");
    setWishlistRecommendedSection(!!course?.wishlist ? 1 : 0);
    if (!isLoadingWishlistLocal) {
      if (!course?.wishlist) {
        // window?.utag?.link({
        //   id: TealiumEvents.fav_add,
        //   Products_Id_Array: [course.courseIdMaster],
        //   Products_ModelName_Array: [course.courseFullName],
        //   product_format: course.ctype,
        //   Products_Category_Array: course.catalogTypes,
        //   Products_Mandatory_Array: isMandatory(course) ? ['Y'] : ['N'],
        //   Page_DeviceType: 'web',
        // });
      }
      // IF isInCmsNewPage handle differently the add to wishlist
      if(isInCmsNewPage) {
        toggleWish(course.courseIdMaster, !wishlistNewPage ? 1 : 0);
        setWishlistNewPage(!wishlistNewPage)
        setIsLoadingWishlistLocal(true);
        // EXIT function
        return; 
      }
      // IF isInRecommendedSection handle differently the add to wishlist
      if (recommendedSectionExists) {
        setWishlistRecommendedSection(!wishlistRecommendedSection ? 1 : 0); 
        toggleWish(course.courseIdMaster, !wishlistRecommendedSection);
        // EXIT function
        return; 
      }

      // OTHERWISE handle it AS-IS
      toggleWish(course.courseIdMaster, !course?.wishlist ? 1 : 0);
      setIsLoadingWishlistLocal(true);
    }
  };

  const handleClickPlayButtonLivestream = e => {
    if (e) {
      e.stopPropagation();
    }

    if (isLivestreamCourse(course)) {
      const urlsLivestream = getLivestreamUrls(course);
      if (urlsLivestream?.liveUrl && course.showJoin) {
        props.toggleIframePopup(true, course, null, null, false, urlsLivestream);
      }
    }
  };

  // const closeCourseModal = () => {
  //   setCurrentCourseInModal(null);
  //   toggleModal(false);
  // }

  const getPriceLabel = () => {
    if (props.ecommerceEnable && isPriceable(course)) {
      if (isPurchased(course) && variant !== 'card-educational-paths-section-master' && variant !== 'card-educational-paths-section-master-career') {
        return lang.PURCHASED;
      } else if (course?.price && +course.price > 0) {
        return formatCurrency(course.price, userProfile?.ecommerceInfo?.currency, userProfile?.preferredLang);
      }
    }

    return '';
  };

  const getEl360Price = () => {
    if(isCourseEl360(course) && isUserEl360(userProfile)) {
      return formatCurrency(course.fullPrice, userProfile?.ecommerceInfo?.currency, userProfile?.preferredLang);
    }
    return ''
  }

  const getEL360Label = () => {

    if (props.ecommerceEnable && isUserEl360(userProfile)) {
      if(isCourseEl360(course) &&  variant !== 'card-educational-paths-section-master') {
        return lang.EL360_LABEL
      }
    }

    return ''
  };

  const [courseFullName, setCourseFullName] = useState<string>('');
  // const [courseSummary, setCourseSummary] = useState<string>('');

  useEffect(() => {
    setCourseFullName(getCourseFullName(course, lang));
    // setCourseSummary(getCourseSummary(course, variant, inView));
    getCourseSummary(course, variant, inView)

    if(variant === "card-role-specific-program-educational-path" && isCourseCertificateCtype(course)) {
      let chart = course?.certificateChartInfo;
      if(chart.doneCourses === chart.totalCourses) {
        setCourseFullName(getCourseFullName(course, lang, careerProgramType))
      }
    }
  }, [course, lang, variant, careerProgramType, inView]);

  const [isAdvancedCompleted, setIsAdvancedCompleted] = useState(false);
    const [courseContentId, setCourseContentId] = useState(null);

  useEffect(() => {
    setIsAdvancedCompleted(isAdvancedCompletedCourse)
  }, [isAdvancedCompletedCourse])

  //CONTENT ID FOR CMS PREVIEW
  useEffect(() => {
    setCourseContentId(coursesContentId)
  }, [coursesContentId])

  const getCourseSummary = (crs: Course, variant: string, inView: boolean): string => {
    if (!crs) {
      return '';
    }

    if(inView) {
        if(crs && crs?.courseSummary == undefined && !isLoadingCourseSummary?.[crs?.courseId]) {
          switch(variant) {
            case "card-evolution":
            case "card-large-evolution":
            case "lesson-in-onboarding":
              break;
            default: 
              dispatch(actions.getCourseSummary([crs], coursesMap));
          }
        }
    }


    if (variant == "lesson-in-onboarding") {
      if(crs && crs?.courseSummary == undefined && !isLoadingCourseSummary?.[crs?.courseId] && !learningPath) {
        dispatch(actions.getCourseSummary([crs], coursesMap))
      }
    }

    return crs.courseSummary;
  };

  // STATE necessary to handle wishlist UI for CMS New Pages carousels
  const [wishlistNewPage,setWishlistNewPage] = useState<boolean>(course ? !!course.wishlist : false)
  const isInRecommendedSection = document.querySelector(".recommended-section");

  const getIsWishlist = (): boolean => {
    if(isInCmsNewPage) return wishlistNewPage
    if(isInRecommendedSection) return !!wishlistRecommendedSection
    if(course) return !!course.wishlist
    return false
  }

  const getProps = () => {
    return {
      courseId: course ? course.courseId : '',
      courseIdMaster: course ? course.courseIdMaster : '',
      courseFullName: courseFullName,
      image: course.courseOverviewFile,
      fallbackImage: course.fallbackImage,
      ctypeName: ctypeName,
      duration: duration,
      iconName: iconName,
      badgeLabel: badge.label,
      badgeColor: badge.color,
      badgePriority: badge.priority || 999999999,
      courseStatus: '',
      showBar: showBar,
      valueBar: valueBar,
      isWishlist: getIsWishlist(),
      isLoadingWishlist: isLoadingWishlist,
      isLoadingWishlistLocal: isLoadingWishlistLocal,
      toggleWishlist: handleToggleWish,
      openCourseModal: openCourseModal,
      isNotYetAvailable: isNotYetAvailable,
      teacherImage: course?.teacherImage,
      teacherName: teacherName,
      course: course,
      showLaunchButton: props.showLaunchButton,
      toggleIframePopup: props.toggleIframePopup,
      compatibility: compatibility,
      isStatic: course ? course.isStatic : false,
      isLiveStream: isLivestreamCourse(course),
      isLiveNow: course.showJoin || isLivestreamLiveNow(course as Course, props.userProfile),
      useBadgeLabelLive: isLivestreamCourse(course) || isVirtualClassroom(course),
      showLivePlay: false,
      gradientStyle: props.gradientStyle,
      previewVideoUrl: getVideoPreviewUrl(course),
      videoVTTUrl:getVTTProgramUrl(course),
      isMandatory: isMandatory(course),
      catalogTypes: course.catalogTypes,
      subtitle: props.subtitle,
      tabIndex: tabIndex,
      credits: course.credits,
      // summary: printHTML(courseSummary),
      summary: printHTML(course?.courseSummary),
      isParentLpBlocked: props.isParentLpBlocked,
      blockingLps: props.blockingLps,
      delayGetCourseDetails: props.delayGetCourseDetails,
      handleClickPlayButtonLivestream: handleClickPlayButtonLivestream,
      priceLabel: getPriceLabel(),
      isPastEvent: checkUpcomingOrPastEvent(course, props.userProfile)?.[0]?.isPastEvent,
      orderNum: props.orderNum,
      dataElementId: props?.['data-element-id'],
      enableWishlist: course && !course?.isStatic && !isNotYetAvailable,
      noHoverEffect: props.noHoverEffect,
      onHover: props.onHover,
      className: props.className,
      isSupportLessonInOnboarding: isSupportLessonInOnboarding(course),
      isAssignment: isAssignmentLesson(course),
      isOneToOne: isOneToOneLesson(course),
      isSupportMaterial: isSupportMaterialLesson(course),
      hidePricelabel: props.hidePricelabel,
      canCourseBeAccessed: canCourseBeAccessed(course),
      setShowShareModal:setShowShareModal,
      labelEl360: getEL360Label(),
      priceEl360: getEl360Price(),
      typeHarvard: course?.typeHarvard,
      isComingSoon: isCourseComingSoon(course),
      isCertificate: isCourseCertificateCtype(course),
      isAssessment: isCourseAssessment(course),
      isAdvancedCompleted: isAdvancedCompletedCourse,
      isUpskillProgram: props.isUpskillProgram,
      showTickOnCompleteLP: props.showTickOnCompleteLP,
      courseContentId: coursesContentId,
      cardRef: cardRef,
      isProductCarousel: props.isProductCarousel,
      fatherCourseId: fatherCourseId,
      clickObjectTracking: props.clickObjectTracking,
      clickObjectTrackingDescription: props.clickObjectTrackingDescription,
      noLazyLoadImage: props.noLazyLoadImage,
    };
  };

  const printLessonInOnboardingCard = () => {
    const cardProps = getProps();

    return (
      <CardActivity
        className="lesson-in-onboarding"
        id={cardProps.courseIdMaster}
        name={cardProps.courseFullName}
        description={cardProps.summary}
        ctypeName={cardProps.ctypeName}
        duration={cardProps.duration}
        isBlocked={cardProps.isNotYetAvailable || cardProps.isParentLpBlocked}
        isProgramLocked={props.isProgramLocked}
        image={cardProps.image}
        isLessonInOnboarding
        course={course}
        valueBar={cardProps.valueBar}
        onClickPlayIcon={() => { }}
        onClickCard={openCourseModal}
        delayImageLoading={props.delayImageLoading}
        topic3Id={props.id}
        credits={cardProps.credits}
        orderNumber={cardProps.orderNum}
        isSupportLessonInOnboarding={cardProps.isSupportLessonInOnboarding}
        isAssignment={cardProps.isAssignment}
        isOneToOne={cardProps.isOneToOne}
        isSupportMaterial={cardProps.isSupportMaterial}
        hidePlayButton={!cardProps.canCourseBeAccessed}
        cardRef={cardProps.cardRef}
        fatherCourseId={fatherCourseId}
      />
    );
  };
  const printCardBigEvolution = () => {
    const cardProps = getProps();
    return (
      <CardBigEvolution
        tag={{
          label: cardProps.badgeLabel,
          color: cardProps.badgeColor,
          priority: cardProps.badgePriority,
        }}
        compatible={lang?.PERCENT_COMPATIBLE?.replace(
          '{percent}',
          (cardProps.compatibility || 0)?.toString()
        )}
        {...cardProps}
      />
    );
  };

  const printCardLargeEvolution = () => {
    const cardProps = getProps();
    return (
      <CardLargeEvolution
        tag={{
          label: cardProps.badgeLabel,
          color: cardProps.badgeColor,
          priority: cardProps.badgePriority,
        }}
        {...cardProps}
        postAction={props.postAction}
      />
    );
  };

  return (
    <>
      {variant === 'small' && <CardSmall {...getProps()} />}
      {variant === 'training-pill' && <CardControllerProduct product={props.product} />}
      {variant === 'overview' && <CardOverview {...getProps()} />}
      {variant === 'lesson-in-lp' && <CardLessonInLP {...getProps()} />}
      {variant === 'upcoming-events' && <CardUpcomingEvents {...getProps()} />}
      {variant === 'lesson-in-onboarding' && printLessonInOnboardingCard()}
      {variant === 'card-evolution' && (
        <>
          {isL1Course(course) ? (
            <CardL1Big
              onClick={props.onClick}
              beforeOnClickCard={props.beforeOnClickCard}
              elementPosition={elementPosition}
              l1={course.l1}
              l1ContentId={course?.contentId}
              tabIndex={tabIndex}
              tabletVariant={props.breakpoint && props.breakpoint.isTablet}
              dataElementId={props?.['data-element-id']}
            />
          ) : (
            printCardBigEvolution()
          )}
        </>
      )}
      {variant === 'card-large-evolution' && printCardLargeEvolution()}
      {variant === 'search' &&
        (isL1Course(course) ? (
          <CardL1Search
            onClick={props.onClick}
            l1={course.l1}
            l1ContentId={course?.contentId}
            tabIndex={tabIndex}
            dataElementId={props?.['data-element-id']}
          />
        ) : (
          <CardSearch {...getProps()} />
        ))}
      {variant === 'card-educational-paths-program' && (
        <CardEducationalPathsProgram {...getProps()} />
      )}
      {variant === 'card-role-specific-program-educational-path' && (
        <CardRoleSpecificProgramEducationalPath {...getProps()} isAdvancedCompletedCourse={isAdvancedCompleted} coursesContentId={courseContentId} />
      )}
      {variant === 'card-educational-paths-section' && (
        <CardEducationalPathsSection {...getProps()} />
      )}
      {variant === 'card-educational-paths-section-master' && (
        <CardEducationalPathsSection {...getProps()} isMaster />
      )}
      {variant === 'card-educational-paths-section-master-career' && (
        <CardEducationalPathsSection {...getProps()} isMaster isCareer />
      )}
      {variant === 'card-highlights-menu' && <CardHighlightsMenu {...getProps()} />}
      {variant === 'card-areaofexpertise' && <CardAreaOfExpertise {...getProps()} />}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    lang: state.utils.lang,
    isLoadingWishlist: state.course.isLoadingWishlist,
    currentCourse: state.course.currentCourse,
    gradientStyle: state.utils.gradientStyle,
    userProfile: state.user.userProfile,
    ecommerceEnable: state.user.ecommerceEnable,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModal: (value, course) => dispatch(actions.toggleModal(value, course)),
    toggleWish: (id, value) => dispatch(actions.toggleWish(id, value)),
    toggleIframePopup: (value, course, url, urlToCheck, isVideo, urlsLivestream) =>
      dispatch(actions.toggleIframePopup(value, course, url, urlToCheck, isVideo, urlsLivestream)),
    setShowModalCart: show => dispatch(actions.setShowModalCart(show)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CardController);
