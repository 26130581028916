import React, { FC, useEffect, useRef, useState } from "react"
import { PlayerProps, UpdateVideoTracking } from "@model/VideoTracking/VideoTrackingClasses";
import { getSecondsInterval, getVideoProgressCookie, sendVideoProgress } from "@utility/VideoTracking/videoTrackingUtility";
import { isVideoCourse, isVirtualClassroom } from "@utility/Api";
var playerjs = require('player.js');

/**
 * In this component we handle all the stuff to track the video progress, video current time and completion
 * @param course --> course rapresenting the video to track
 * @param urlToCheck --> url to send completion to moodle
 * @returns basically an invisible iframe when progress percentage is >= 80%, the src of the iframe is the urlToCheck
 */
const Player: FC<PlayerProps> = ({
    course,
    urlToCheck
}) => {

    // Get course details content
    const courseDetailsContent = course?.courseDetails?.[0]?.content?.[0];
    const moduleId = courseDetailsContent?.moduleId;
    // Get video progress cookie (if any)
    const videoProgressCookie = getVideoProgressCookie(course?.courseId);
    // Initial video current time value
    const initialTime = videoProgressCookie && videoProgressCookie.currentTime 
        ? videoProgressCookie.currentTime 
        : courseDetailsContent?.videoCurrentTime;
    // Initial video progress value
    const initialProgress = videoProgressCookie && videoProgressCookie.progress 
        ? videoProgressCookie.progress 
        : courseDetailsContent?.videoProgress;
    // Ref to manage the interval
    const intervalRef = useRef<NodeJS.Timeout | null>(null); 
    // Ref to save the current time of the video
    const playerTimeRef = useRef<number>(initialTime);
    // Ref to save the progress of the video
    const playerProgressRef = useRef<number>(initialProgress);
    // Ref to save the wrong progress of the video (case when I start from a point and the I go back to the beginning)
    const wrongProgressRef = useRef<number>(0);
    // Ref to save the duration of the video
    const playerDurationRef = useRef<number>(null);
    // tolerance used in some calculations
    // const tolerance = 2; 
    // State to save intervalSeconds used to set the interval for sendVideoProgress
    const [intervalSeconds, setIntervalSeconds] = useState<number>(0);
    // State to track if the video is playing
    const [isPlaying, setIsPlaying] = useState<boolean>(false); 
    // State to handle the rendering of the invisible ifram used to send completion to moodle
    const [isPercentageEnough, setIsPercentageEnough] = useState<boolean>(false);

    // Function to start the interval
    const startInterval = () => {
        // Prevent multiple intervals
        if (intervalRef.current || intervalSeconds === 0) return; 
        // Set the interval
        intervalRef.current = setInterval(() => {
            const updateVideoTracking: UpdateVideoTracking = {
                lessonId: course?.courseId,
                moduleId: moduleId,
                currentTime: Math.floor(playerTimeRef.current),
                progress: playerProgressRef.current
            }
            sendVideoProgress(updateVideoTracking);
        }, intervalSeconds * 1000);
    };
    // Function to stop the interval
    const stopInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current); // Stop the interval
            intervalRef.current = null; // Reset the interval ref
        }
    };
    // Function to calculate the progress
    const calculateProgress = (currentPercentage: number): number => {
        
        // IF initialProgress is 95% --> return 100 (it's almost completed)
        if(initialProgress >= 95) return 100
        // PREVENT PERCENTAGE CALCULATION IF PERCENTAGE IS 100
        if(playerProgressRef.current >= 100) return 100
        // I move backward from where I started the video
        if(playerTimeRef.current < initialTime) {
            wrongProgressRef.current++;
            return initialProgress
        }
        
        return (initialProgress + currentPercentage) - wrongProgressRef.current

    }
    // Function to handle the progress callback as how was done before the tracking system
    const oldProgressCallback = (data: any) => {
        // IF progress percentage > 80 --> SET ISPERCENTAGENOUGH TO TRUE
        if (data?.percentage > 80) setIsPercentageEnough(true)
    }
    // Function to handle the progress callback
    const progressCallback = (data: any) => {
        // CALCULATE progress
        playerProgressRef.current = calculateProgress(data?.percentage) 
        // IF progress > 80% --> SET ISPERCENTAGENOUGH TO TRUE
        if (playerProgressRef.current > 80) setIsPercentageEnough(true)
    }
    // Function to handle the timeupdate callback
    const timeupdateCallback = (player: any) => {
        if(!player) return;
        player.getCurrentTime((value: number) => {
            playerTimeRef.current = value;
        });
    }
    // Function to handle video pause callback
    const pauseCallback = () => {
        setIsPlaying(false);
        stopInterval();
    }
    // Function to handle video play callback
    const playCallback = () => {
        setIsPlaying(true);
        // Ensure the interval starts only when intervalSeconds is set
        if (intervalSeconds > 0) {
            startInterval();
        }
    }
    // Function to handle video ended callback
    const endCallback = () => {
        const updateVideoTracking: UpdateVideoTracking = {
            lessonId: course?.courseId,
            moduleId: moduleId,
            currentTime: calculateTimeOnVideoEnded(),
            progress: playerProgressRef.current
        }
        sendVideoProgress(updateVideoTracking);
    }
    // Function to calculate the time on video ended
    const calculateTimeOnVideoEnded = () => {
        // IF progress < 95% --> RETURN THE TIME WHERE USER SHOULD START BASING ON IT'S COMPLETION PERCENTAGE
        if (playerProgressRef.current < 95) {
            return Math.floor((playerDurationRef.current * playerProgressRef.current) / 100)
        }
        // OTHERWISE RETURN 0 --> VIDEO COMPLETED AND TO RESTART FROM BEGINNING
        return 0;
    };

    useEffect(() => {

        // INITIALIZE PLAYER JS TO TRACK TIME AND PROGRESS
        const initializePlayerJs = () => {
            // TRY API PLAYER JS
            const iframe = document?.getElementById('iframe-video')
            const player = new playerjs.Player(iframe);

            player?.on('ready', () => {
                // IF IS NOT A VIDEO --> USE OLD PROGRESS CALLBACK (this should be the case of VC,LS recording and podcasts)
                if(!isVideoCourse(course)) {
                    return player.on("progress", oldProgressCallback);
                }
                // OTHERWISE --> USE NEW PROGRESS CALLBACK etc...
                let retries = 0;
                // FUNCTION TO TRY TO GET THE DURATION OF THE VIDEO
                const tryGetDuration = (maxRetries = 10, retryInterval = 500) => {
                    player.getDuration((duration: number) => {
                        // IF duration > 0 --> SET THE DURATION AND THE INTERVAL SECONDS
                        if (duration > 0) {
                            getSecondsInterval(Math?.floor(duration)).then((secondsInterval) => {
                                playerDurationRef.current = Math?.floor(duration);
                                // Set interval seconds once retrieved
                                setIntervalSeconds(secondsInterval);
                                // LISTEN on end video
                                if(secondsInterval > 0) player.on('ended', endCallback);
                            })
                        } else if (retries < maxRetries) { // ELSE IF retries < maxRetries --> TRY AGAIN
                            retries++;
                            setTimeout(tryGetDuration, retryInterval);
                        } else {   // OTHERWISE --> FAILED TO GET DURATION
                            console.error("Failed to get duration from PlayerJS");
                        }
                    });
                }
                // Start the retry process for getting the duration
                tryGetDuration();  
                // LISTEN to player progress in order to set percentage enough
                player.on("progress", progressCallback);
                // LISTEN to player timeupdate in order to set the current time
                player.on("timeupdate", () => timeupdateCallback(player));
                // LISTEN to player pause
                player.on('pause', pauseCallback);
                // LISTEN to player play
                player.on('play', playCallback);
            });

        }
        // INITIALIZE PLAYER JS
        initializePlayerJs();
        // CLEANUP --> STOP THE INTERVAL ON UNMOUNTING
        return (() => {
            stopInterval();
        })
    }, [])

    // Add useEffect to react to changes in intervalSeconds
    useEffect(() => {
        if (intervalSeconds > 0 && isPlaying) {
            startInterval();
        }
        return () => {
            stopInterval(); // Stop the interval if the component unmounts or intervalSeconds changes
        };
    }, [intervalSeconds, isPlaying]);

    return (<>
        {/* IFRAME to send completion after 80% */}
        {urlToCheck && isPercentageEnough &&
            <iframe
                src={urlToCheck}
                allowFullScreen
                id="iframe-video"
                style={{ display: 'none' }}
                tabIndex={0}
            />
        }
    </>);

}

export default Player;